import { Box, Grid, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { pxToRem } from "shared/helpers";
import { AppState } from "store";
import actions from "store/eventSounds/actions";

const useStyles = makeStyles((theme: any) => {
  return {
    input: {
      fontSize: theme.custom.typography.fontSize["14"],
      letterSpacing: theme.custom.typography.letterSpacing["medium"],
      paddingTop: pxToRem(13.5),
      paddingBottom: pxToRem(13.5),
    },
  };
});

const MinAndMaxValue = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();

  const { minValue, maxValue } = useSelector(
    (state: AppState) => state.eventSounds
  );

  const [valueMin, setValueMin] = useState<any>(minValue);
  const [valueMax, setValueMax] = useState<any>(maxValue);
  const inputMinRef = useRef<HTMLDivElement>(null);
  const inputMaxRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const dispatch: any = useDispatch();

  const handleMinValueTextBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = e.target;
      dispatch(actions.setMinValue(value ? value : null));
    },
    []
  );

  const handleMinValueKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter") inputMinRef?.current?.blur();
    },
    []
  );

  const handleMinValueChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValueMin(target.value ? target.value : null);
    },
    []
  );

  const handleMaxValueTextBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = e.target;
      dispatch(actions.setMaxValue(value ? value : null));
    },
    []
  );

  const handleMaxValueKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter") inputMinRef?.current?.blur();
    },
    []
  );

  const handleMaxValueChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValueMax(target.value ? target.value : null);
    },
    []
  );

  return (
    <>
      <Grid item xs={12} sm={9} lg={3}>
        <TextField
          label={t("eventSounds.dataSelection.filter.labels.minValue")}
          value={valueMin ?? null}
          onChange={handleMinValueChange}
          variant="outlined"
          type="number"
          size="small"
          inputProps={{
            className: classes.input,
          }}
          style={{ width: "100%" }}
          onBlur={handleMinValueTextBlur}
          onKeyDown={handleMinValueKeyPress}
          inputRef={inputMinRef}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={9} lg={3}>
        <TextField
          label={t("eventSounds.dataSelection.filter.labels.maxValue")}
          value={valueMax ?? null}
          onChange={handleMaxValueChange}
          variant="outlined"
          type="number"
          size="small"
          inputProps={{
            className: classes.input,
          }}
          style={{ width: "100%" }}
          onBlur={handleMaxValueTextBlur}
          onKeyDown={handleMaxValueKeyPress}
          inputRef={inputMaxRef}
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

export default MinAndMaxValue;
