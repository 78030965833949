import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

type TParams = {
  name: string;
  datasets: { dataset: number }[];
};

export default async function addDatasetGroup(params: TParams) {
  const url = endpoints.similaritySearchService.datasetGroup;
  try {
    const response = await axiosNeuron.post(url, params);
    const { data } = response;
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
