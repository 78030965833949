import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { IAudioChunk, IDatasetDetail } from "types/eventSounds";

export default async function getDataset(id: number) {
  const url = endpoints.similaritySearchService.datasetDetail(id);
  try {
    const { data } = await axiosNeuron.get(url);
    data.results = data.results.map((item: IDatasetDetail) => {
      return {
        ...item,
        audio_chunks: item.audio_chunks.map((chunk: IAudioChunk) => ({
          ...chunk,
          isPlaying: false,
        })),
      };
    });
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
