export enum EActions {
  setCommonData = "setCommonData",
  setZoom = "setZoom",
  setFFT = "setFFT",
  setLoadingBuffer = "setLoadingBuffer",
  setBufferLoaded = "setBufferLoaded",
  setDraw = "setDraw",
  setLoadingCommonData = "setLoadingCommonData",
  changeTimezone = "changeTimezone",
  setReset = "setReset",
  setZoomOutLoading = "setZoomOutLoading",
  setBulkZoom = "setBulkZoom",
  setPlaying = "setPlaying",
  setLargeDownloadAllowed = "setLargeDownloadAllowed",
  setVolume = "setVolume",
  setLabelsFilterData = "setLabelsFilterData",
  setIsPreviousClicked = "setIsPreviousClicked",
  setIsNextClicked = "setIsNextClicked",

  setCheckedLabels = "setCheckedLabels",
  setIsChecked = "setIsChecked",
  setShowHiddenLabels = "setShowHiddenLabels",
  setIsCombinationOnly = "setIsCombinationOnly",
  setDateTime = "setDateTime",
  setEditMode = "setEditMode",
  setIsConfirmed = "setIsConfirmed",
}

export interface ISoundsState {
  draw: string;
  isDocumentEventActive: boolean;
  loaded: boolean;
  loadingData: boolean;
  renderFFT: boolean;
  allLabels: any[] | null;
  zoom: number;
  localZoom: number;
  sampleRate: number;
  year?: string;
  yearData: any[] | null;
  date?: string;
  labelsDates: undefined;
  dayData: null;
  time?: string;
  timezoneOffset: string;
  duration: number;
  placements: { [key: string]: any } | undefined;
  zoomOutOffset: number;
  zoomOutLoading: boolean;
  bufferStart?: number;
  loadingCommonData: boolean;
  isPlaying: number | null;
  largeDownloadAllowed: boolean;
  labelsFilterData: any[] | null;
  isPreviousClicked?: boolean;
  isNextClicked?: boolean;
  checkedLabels?: { name: string; subcategory: string }[];
  isChecked?: boolean;
  isHiddenLabelShown?: boolean;
  isCombinationOnly?: boolean;
  editMode: boolean;
  isConfirmed?: boolean;
}

interface IActionData {
  data: {
    placements: any[];
    yearData: any[];
    allLabels: any[];
    dayData: any[];
    date: string;
    time: string;
    timezoneOffset: string;
    machine: number;
    duration: number;
  };
  zoom: number;
  renderFFT: boolean;
  loadingBuffer: boolean;
  loading: boolean;
  placement: number;
  buffer: any;
  sr?: number | null;
  rest: any;
  draw: string;
  largeDownloadAllowed: boolean;
  volume: number;
  amplifiedBuffer: any;
  labelsFilterData: any[] | null;
  isPreviousClicked?: boolean;
  isNextClicked?: boolean;
  checkedLabels?: { name?: string; subcategory?: string }[];
  isChecked?: boolean;
  isHiddenLabelShown?: boolean;
  isCombinationOnly?: boolean;
  date: string;
  time: string;
  editMode: boolean;
  isConfirmed?: boolean;
}

export interface IActionTypes {
  type: typeof EActions;
  payload: IActionData;
}

export type TReducers = {
  [key: string]: (p: IActionTypes, s: ISoundsState) => void;
};
