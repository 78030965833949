import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { useTranslation } from "react-i18next";
import MultiSelect from "./multiSelect";
import actions from "store/eventSounds/actions";
import { IEventTypeList } from "types/event";

function EventType({ disabled }: { disabled: boolean }) {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();

  const { eventTypes, eventTypeValues } = useSelector(
    (state: AppState) => state.eventSounds
  );

  const onChange = useCallback(
    (values: string[]) => {
      dispatch(actions.setEventTypeValues(values));
    },
    [dispatch]
  );

  const data = eventTypes?.data.map((item: IEventTypeList) => ({
    ...item,
    id: item.codename,
    name: t(`eventTypeNames.${item.name}`),
    eventName: item.name,
  }));

  return (
    <MultiSelect
      data={data}
      loading={eventTypes?.loading}
      selectedValues={eventTypeValues || []}
      onChange={onChange}
      disabled={disabled}
      label={t("eventSounds.dataSelection.filter.eventType")}
      placeholder={t("eventSounds.dataSelection.searchPlaceholder.eventType")}
      id="event-type-select"
    />
  );
}

export default EventType;
