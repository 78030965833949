import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { addLabel } from "api/handlers/dataLabeling/addLabel";
import Checkbox from "components/checkbox";
import { ModalWrap } from "components/events/components/modalWrap";
import Heading3 from "components/typography/heading/heading3";
import { enqueueSnackbar } from "notistack";
import React, { memo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { AppState } from "store";
import actions, {
  fetchLabels,
} from "store/machineDetail/analysisSounds/actions";
import IMuiTheme from "types/styles";

const useStyles = makeStyles((theme: IMuiTheme) => ({
  modalWrapper: {
    [`@media only screen and (min-width: ${theme.spacing(
      102
    )}) and (min-height: ${theme.spacing(72)})`]: {
      width: "80%",
      height: theme.spacing(75),
    },
    [`@media only screen and (min-width: ${theme.spacing(
      130
    )}) and (min-height: ${theme.spacing(72)})`]: {
      width: "65%",
      height: theme.spacing(80),
    },
    [`@media only screen and (min-width: ${theme.spacing(
      160
    )}) and (min-height: ${theme.spacing(72)})`]: {
      width: "50%",
      height: theme.spacing(80),
    },
    "& .MuiGrid-item": {
      paddingTop: 0,
    },
  },
}));

interface ILabelModal {
  open: boolean;
  placement: number;
  machineId: number;
  timezoneOffset: any;
  onCancel: (e?: React.MouseEvent<HTMLButtonElement>) => void;
}

const { setLabelValues } = actions;

const LabelModal = memo(
  ({ open, placement, machineId, timezoneOffset, onCancel }: ILabelModal) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch: any = useDispatch();

    const { labels, labelValues, labelTimeInterval } = useSelector(
      (state: AppState) => state.machineDetail.analysisSounds
    );

    const { data: labelList, loading } = labels;
    const handleSubmit = useCallback(async () => {
      onCancel();

      try {
        enqueueSnackbar(
          t("labelModal.add.success", {
            variant: "error",
          })
        );
        return labelValues.map(
          async (label: any) =>
            await addLabel(
              { name: label.name, subcategory: label.subcategory },
              labelTimeInterval.start,
              labelTimeInterval.end,
              [placement],
              timezoneOffset,
              null,
              machineId
            )
        );
      } catch {
        enqueueSnackbar(
          t("labelModal.add.fail", {
            variant: "error",
          })
        );
      }
    }, [
      labelValues,
      placement,
      machineId,
      timezoneOffset,
      labelTimeInterval,
      onCancel,
      enqueueSnackbar,
    ]);

    useEffect(() => {
      dispatch(fetchLabels({ machine: machineId }));
    }, [dispatch, machineId]);

    return (
      <ModalWrap
        open={open}
        title={t("labelModal.titles.label")}
        cta={t("labelModal.actions.label")}
        submitDisabled={!labelValues.length}
        onCancel={onCancel}
        className={classes.modalWrapper}
        loading={loading}
        onSubmit={handleSubmit}
      >
        <Box pl={2} pr={2} paddingBottom={2}>
          <Heading3>{t("labelModal.checkbox.heading")}</Heading3>
          {labelList?.map((label: any) => {
            const isChecked = labelValues.some(
              (item: any) => label.id === item.subcategory
            );
            return (
              <Box>
                <Checkbox
                  label={label.name}
                  onChange={(e) => {
                    if (isChecked) {
                      dispatch(
                        setLabelValues(
                          labelValues.filter(
                            (item: any) => label.id !== item.subcategory
                          )
                        )
                      );
                    } else {
                      dispatch(
                        setLabelValues([
                          ...labelValues,
                          {
                            name: label.name,
                            subcategory: label.id,
                          },
                        ])
                      );
                    }
                  }}
                  checked={isChecked}
                />
              </Box>
            );
          })}
        </Box>
      </ModalWrap>
    );
  }
);

export default LabelModal;
