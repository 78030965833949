import axiosNeuron, { axiosNeuronCustom } from "api/axios";
import endpoints from "api/endpoints";

type Params = {
  machineId?: number;
  datasetGroupId?: number;
  trainingJobTag?: string;
  traceId?: string;
  environment?: string;
  trainingType?: string;
  modelCategory?: string;
  modelUrls?: string;
  regressionTargets?: {
    class: number;
    numeric_target: number;
  }[];
  nominalClasses?: number[];
  customName?: string | null;
};

export default async function trainModel({
  machineId,
  datasetGroupId,
  environment,
  trainingJobTag,
  traceId,
  trainingType,
  modelCategory,
  modelUrls,
  regressionTargets,
  nominalClasses,
  customName,
}: Params) {
  const url = machineId
    ? endpoints.machines.train(machineId)
    : endpoints.similaritySearchService.trainDatasetGroup;

  const axios = traceId
    ? axiosNeuronCustom({
        "X-Trace-Id": traceId,
      })
    : axiosNeuron;

  try {
    const response = await axios.post(url, {
      environment,
      training_job_tag: trainingJobTag,
      dataset_group: datasetGroupId,
      training_type: trainingType,
      model_category: modelCategory,
      model_urls: modelUrls?.length ? modelUrls : undefined,
      regression_targets: regressionTargets,
      nominal_classes: nominalClasses,
      custom_model_training_name: customName,
    });
    return response;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
