import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

export default async function getDatasetGroup(id: number) {
  const url = endpoints.similaritySearchService.datasetGroupDetail(id);

  try {
    const response = await axiosNeuron.get(url);
    const { data } = response;
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
