import { EActions, IProjectState, IActionTypes } from "./types";
import { createReducer } from "store/helpers";
import { path } from "./actions";

const initialState: IProjectState = {
  initialPlacements: undefined,
  placements: undefined,
  pageIndex: 0,
  pageSize: 15,
  loading: false,
  reloading: true,
  editing: null,
  sort: [{ id: "id", desc: false }],
  showMore: 0,
  sensorTypes: [],
  placementTypes: [],
  recordingPlans: [],
  rules: [],
  currentRules: [],
  isSaving: false,
  isSuccessfull: false,
  optionsRules: "",
  min_count: 0,
  customModels: {
    data: [],
    isFetching: false,
    error: null,
  },
  predictionType: "",
};

const updatePlacement = (
  state: IProjectState,
  id: number | undefined,
  update: any
) => {
  return {
    ...state.placements,
    results: state.placements?.results.map((item) => {
      if (item.id === id) {
        return { ...item, ...update };
      }
      return item;
    }),
  };
};

const reducers = {
  [EActions.setPlacements]: ({ payload: { placements } }: IActionTypes) => ({
    placements,
    initialPlacements: placements,
    loading: false,
    reloading: false,
    editing: null,
  }),
  [EActions.updateSuccessfullPlacement]: ({
    payload: { isSuccessfull },
  }: any) => ({
    isSuccessfull: isSuccessfull,
  }),
  [EActions.setIsSaving]: ({ payload: { isSaving } }: any) => ({
    isSaving: isSaving,
  }),
  [EActions.setLoading]: ({ payload: { loading } }: IActionTypes) => ({
    loading,
  }),
  [EActions.setReloading]: ({ payload: { reloading } }: IActionTypes) => ({
    reloading,
  }),
  [EActions.setPage]: ({ payload: { pageIndex } }: IActionTypes) => ({
    pageIndex,
  }),
  [EActions.setEditing]: ({ payload: { editing } }: IActionTypes) => ({
    editing,
  }),
  [EActions.setSort]: ({ payload: { sort } }: IActionTypes) => ({ sort }),
  [EActions.setShowMore]: ({ payload: { showMore } }: IActionTypes) => ({
    showMore,
  }),
  [EActions.setReset]: () => ({ ...initialState }),
  [EActions.setDiscard]: (_: IActionTypes, state: IProjectState) => ({
    placements: { ...state.initialPlacements },
  }),
  [EActions.setSensorTypes]: ({ payload: { sensorTypes } }: IActionTypes) => ({
    sensorTypes,
  }),
  [EActions.setPlacementTypes]: ({
    payload: { placementTypes },
  }: IActionTypes) => ({
    placementTypes,
  }),
  [EActions.setRecordingPlans]: ({
    payload: { recordingPlans },
  }: IActionTypes) => ({
    recordingPlans,
  }),
  [EActions.setPlacementStatus]: (
    { payload: { placement, is_enabled } }: IActionTypes,
    state: IProjectState
  ) => ({
    placements: updatePlacement(state, placement, { is_enabled }),
  }),
  [EActions.setPlacementThreshold]: (
    { payload: { placement, running_threshold } }: IActionTypes,
    state: IProjectState
  ) => ({
    placements: updatePlacement(state, placement, { running_threshold }),
  }),
  [EActions.setPlacementCable]: (
    { payload: { placement, sensor_cable_length } }: IActionTypes,
    state: IProjectState
  ) => ({
    placements: updatePlacement(state, placement, { sensor_cable_length }),
  }),
  [EActions.setPlacementRPM]: (
    { payload: { placement, rpm } }: IActionTypes,
    state: IProjectState
  ) => ({
    placements: updatePlacement(state, placement, {
      rpm,
    }),
  }),
  [EActions.setPlacementRPMAuto]: (
    { payload: { placement, is_rpm_automatically_detected } }: IActionTypes,
    state: IProjectState
  ) => ({
    placements: updatePlacement(state, placement, {
      is_rpm_automatically_detected,
    }),
  }),
  [EActions.setPlacementBandAlerting]: (
    { payload: { placement, band_alerting } }: IActionTypes,
    state: IProjectState
  ) => ({
    placements: updatePlacement(state, placement, {
      band_alerting,
    }),
  }),
  [EActions.rename]: (
    { payload: { placement, customName } }: IActionTypes,
    state: IProjectState
  ) => ({
    placements: updatePlacement(state, placement, { customName }),
    editing: null,
  }),
  [EActions.setSensorType]: (
    { payload: { placement, sensor_type } }: IActionTypes,
    state: IProjectState
  ) => ({
    placements: updatePlacement(state, placement, {
      sensor_type: sensor_type && sensor_type > 0 ? sensor_type : null,
    }),
  }),
  [EActions.setPlacementType]: (
    { payload: { placement, type } }: IActionTypes,
    state: IProjectState
  ) => ({
    placements: updatePlacement(state, placement, {
      type,
    }),
  }),
  [EActions.setIsoGroup]: (
    { payload: { placement, iso_group } }: IActionTypes,
    state: IProjectState
  ) => ({
    placements: updatePlacement(state, placement, {
      iso_group: iso_group && iso_group > 0 ? iso_group : null,
    }),
  }),
  [EActions.setRecordingPlan]: (
    { payload: { placement, recording_plan } }: IActionTypes,
    state: IProjectState
  ) => ({
    placements: updatePlacement(state, placement, {
      recording_plan: recording_plan || null,
    }),
  }),
  [EActions.setSuppressNotificationsUntil]: (
    { payload: { placement, suppress_notifications_until } }: IActionTypes,
    state: IProjectState
  ) => ({
    placements: updatePlacement(state, placement, {
      suppress_notifications_until,
    }),
    initialPlacements: updatePlacement(state, placement, {
      suppress_notifications_until,
    }),
  }),
  [EActions.setPlacementEnvelopeSad]: (
    { payload: { placement, envelope_sad } }: IActionTypes,
    state: IProjectState
  ) => ({
    placements: updatePlacement(state, placement, {
      envelope_sad,
    }),
  }),
  [EActions.setPlacementPrediction]: (
    {
      payload: { placement, is_used_for_machine_running_prediction },
    }: IActionTypes,
    state: IProjectState
  ) => ({
    placements: updatePlacement(state, placement, {
      is_used_for_machine_running_prediction,
    }),
  }),
  [EActions.setInterval]: (
    { payload: { placement, interval } }: IActionTypes,
    state: IProjectState
  ) => {
    const currentPlacement = state?.placements?.results?.find(
      (item) => item.id === placement
    );
    const alertingConfig = currentPlacement?.alerting_config;
    const rule = alertingConfig?.rules ? alertingConfig.rules : [];
    const alerting_config = {
      ...alertingConfig,
      rules: [
        {
          ...rule[0],
          interval,
        },
        ...rule.slice(1),
      ],
    };
    return {
      placements: updatePlacement(state, placement, {
        alerting_config,
      }),
    };
  },
  [EActions.setMinCount]: (
    { payload: { index, min_count } }: IActionTypes,
    state: IProjectState
  ) => {
    const updatedRules = state?.currentRules?.map((item, id) => {
      if (id === index) {
        return { ...item, min_count };
      }
      return item;
    });
    return {
      currentRules: updatedRules,
    };
  },
  [EActions.setModelResultType]: (
    { payload: { index, prediction_type } }: IActionTypes,
    state: IProjectState
  ) => {
    const updatedRules = state?.currentRules?.map((rule, id) => {
      if (id === index) {
        return { ...rule, prediction_type };
      }
      return rule;
    });
    return {
      currentRules: updatedRules,
    };
  },
  [EActions.setSelectRule]: (
    { payload: { index, rule } }: IActionTypes,
    state: IProjectState
  ) => {
    const updatedRules = state?.currentRules?.map((item, id) => {
      if (id === index) {
        return { ...item, class_name: rule };
      }
      return item;
    });
    return {
      currentRules: updatedRules,
      optionsRules: rule,
    };
  },
  [EActions.setAlertingThreshold]: (
    { payload: { index, threshold } }: IActionTypes,
    state: IProjectState
  ) => {
    const updatedRules = state?.currentRules?.map((item, id) => {
      if (id === index) {
        const { min_value, max_value, change_value, ...updatedItem } = item;
        return { ...updatedItem, threshold };
      }
      return item;
    });
    return {
      currentRules: updatedRules,
    };
  },
  [EActions.setMinValue]: (
    { payload: { index, min_value } }: IActionTypes,
    state: IProjectState
  ) => {
    const updatedRules = state?.currentRules?.map((item, id) => {
      if (id === index) {
        const { aggregator, threshold, change_value, ...updatedItem } = item;
        return { ...updatedItem, min_value };
      }
      return item;
    });
    return {
      currentRules: updatedRules,
    };
  },
  [EActions.setMaxValue]: (
    { payload: { index, max_value } }: IActionTypes,
    state: IProjectState
  ) => {
    const updatedRules = state?.currentRules?.map((item, id) => {
      if (id === index) {
        const { aggregator, threshold, change_value, ...updatedItem } = item;
        return { ...updatedItem, max_value };
      }
      return item;
    });
    return {
      currentRules: updatedRules,
    };
  },
  [EActions.setChangeValueGradientAggRule]: (
    { payload: { index, change_value } }: IActionTypes,
    state: IProjectState
  ) => {
    const updatedRules = state?.currentRules?.map((item, id) => {
      if (id === index) {
        const { aggregator, threshold, min_value, max_value, ...updatedItem } =
          item;
        return { ...updatedItem, change_value };
      }
      return item;
    });
    return {
      currentRules: updatedRules,
    };
  },
  [EActions.setIntervalToWatch]: (
    { payload: { index, interval } }: IActionTypes,
    state: IProjectState
  ) => {
    const updatedRules = state?.currentRules?.map((item, id) => {
      if (id === index) {
        return { ...item, interval };
      }
      return item;
    });
    return {
      currentRules: updatedRules,
    };
  },
  [EActions.addRules]: (
    { payload: { rules, predictionType } }: IActionTypes,
    state: IProjectState
  ) => {
    const addedRule = {
      enabled: true,
      aggregator: "half_over_threshold",
      prediction_type: predictionType ? predictionType : "anomaly_detector",
      class_name: "ThresholdAggregateRule",
      interval: 30,
      min_count: 20,
      threshold: 0.5,
    };

    return { currentRules: [...rules, addedRule] };
  },
  [EActions.setRules]: ({ payload: { rules } }: IActionTypes) => ({
    rules,
  }),
  [EActions.removeRule]: (
    { payload: { index } }: IActionTypes,
    state: IProjectState
  ) => {
    const updatedRules = state.currentRules?.filter((_, i) => i !== index);
    return {
      currentRules: updatedRules,
    };
  },
  [EActions.setRuleStatus]: (
    { payload: { index, enabled } }: IActionTypes,
    state: IProjectState
  ) => {
    const updatedRules = state?.currentRules?.map((item, id) => {
      if (id === index) {
        return { ...item, enabled };
      }
      return item;
    });
    return {
      currentRules: updatedRules,
    };
  },
  [EActions.updateRules]: ({ payload: { rules } }: IActionTypes) => {
    return { currentRules: rules };
  },
  [EActions.setCustomModelFetching]: (_: any, state: IProjectState) => ({
    customModels: {
      ...state.customModels,
      isFetching: true,
    },
  }),
  [EActions.setCustomModelSuccess]: (
    { payload: { customModels } }: IActionTypes,
    state: IProjectState
  ) => ({
    customModels: {
      data: customModels,
      error: null,
      isFetching: false,
    },
  }),
  [EActions.setCustomModelFail]: (
    { payload: { error } }: any,
    state: IProjectState
  ) => ({
    customModels: {
      data: [],
      error: error,
      isFetching: false,
    },
  }),
};
export default createReducer<IProjectState>(path, initialState, reducers);
