import axiosNeuron from "api/axios";
import endpoints from "api/endpoints";

export default async function deleteDatasetGroup(id: number) {
  const url = endpoints.similaritySearchService.datasetGroupDetail(id);

  try {
    const { data } = await axiosNeuron.delete(url);
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
