import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";

export async function getAvailableLabels({
  machine,
  id,
  ps,
  tz,
  isRawData,
  nextUrl,
  q,
  field,
  not_id,
  isFilter,
}: {
  machine?: number;
  id?: number[];
  ps?: number;
  tz?: string;
  isRawData?: boolean;
  nextUrl?: string;
  q?: string;
  field?: string[];
  not_id?: number[];
  isFilter?: boolean;
}) {
  const url = endpoints.labels.subCategories;

  const params = {
    tz,
    ps: ps || 9999,
    q,
    field,
  };

  try {
    let dataList;
    if (isFilter && id && id.length) {
      const { data } = await axiosNeuron.get(url, {
        params: {
          id,
        },
      });
      const { data: newData } = await axiosNeuron.get(
        nextUrl || `${url}?${machine ? `&machine=${machine}` : ""}`,
        {
          params: {
            ...params,
            not_id,
          },
        }
      );
      const res = {
        ...newData,
        results: [...data.results, ...newData.results],
      };
      dataList = res;
    } else {
      const { data } = await axiosNeuron.get(
        nextUrl || `${url}${machine ? `?machine=${machine}` : ""}`,
        {
          params: {
            ...params,
            id,
          },
        }
      );
      dataList = data;
    }

    const newData = {
      ...dataList,
      results: dataList.results.map(
        ({
          name,
          id,
          category,
          color,
        }: {
          name: string;
          id: number;
          category: number;
          color: string;
        }) => ({
          name,
          subcategory: id,
          category,
          color,
        })
      ),
    };

    return isRawData ? dataList : newData;
  } catch {
    return;
  }
}
