import endpoints from "api/endpoints";
import moment from "moment";
import getAudioContext from "components/dataLabeling/audio/getAudioContext";
import axiosNeuron from "api/axios";
import axios, { Canceler } from "axios";
import { limitConcurrency } from "api/utils";
let cancel: Canceler | undefined;
import { FLACDecoder } from "@wasm-audio-decoders/flac";
import { create, ConverterType } from "@alexanderolsen/libsamplerate-js";

export async function getAudio(
  placement: number,
  start: number,
  length: number,
  timezoneOffset: string,
  sampleRate: number,
  signal: AbortSignal
): Promise<{ buffer: AudioBuffer; sr: number } | null> {
  let ctx = getAudioContext(sampleRate);

  const start_time = moment.unix(start).format("YYYY-MM-DD HH:mm:ss.SSS");
  const end_time = moment
    .unix(start + length)
    .format("YYYY-MM-DD HH:mm:ss.SSS");
  const start_time_tz = moment.tz(start_time, timezoneOffset);
  const end_time_tz = moment.tz(end_time, timezoneOffset);

  const url = endpoints.audioChunks.default;
  let data: any;

  try {
    // Query for chunks starting an hour before the requested start time to include the previous chunk
    const extendedStartTime = moment
      .unix(start - 3600)
      .format("YYYY-MM-DD HH:mm:ss.SSS");

    const audioChunksResponse = await axiosNeuron.get(
      `${url}?placement=${encodeURIComponent(placement)}&from=${encodeURIComponent(extendedStartTime)}&to=${encodeURIComponent(end_time)}&tz=${timezoneOffset}&ps=99999&order_by=start_datetime&order_by=id`,
      {
        responseType: "json",
        cancelToken: signal
          ? undefined
          : new axios.CancelToken(function executor(c) {
              cancel = c;
            }),
      }
    );
    const audioChunks = audioChunksResponse.data.results;

    // Remove duplicate chunks based on unique IDs
    const uniqueChunks = Array.from(
      new Set(audioChunks.map((chunk: any) => chunk.id))
    ).map((id) => audioChunks.find((chunk: any) => chunk.id === id));

    // Filter out chunks that end before the requested start time
    const filteredChunks = uniqueChunks.filter((chunk) => {
      const chunkEndTime = moment(chunk.end_datetime).valueOf();
      return chunkEndTime >= start_time_tz.valueOf();
    });
    if (filteredChunks.length === 0) {
      return null;
    }

    const tasks = filteredChunks.map((chunk: any) => async () => {
      let audioFileResponse: any;
      for (let retries = 10; ; --retries) {
        try {
          audioFileResponse = await axiosNeuron.get(
            endpoints.audioChunks.file(chunk.id),
            {
              responseType: "arraybuffer",
              cancelToken: signal
                ? undefined
                : new axios.CancelToken(function executor(c) {
                    cancel = c;
                  }),
            }
          );
          break;
        } catch (err) {
          if (!retries) {
            throw err;
          }
        }
      }
      var originalBuffer: any;
      try {
        // try to decode with FLACDecoder and resample with libsamplerate-js
        const decoder = new FLACDecoder();
        await decoder.ready; // wait for the WASM to be compiled
        const data = new Uint8Array(audioFileResponse.data);
        const { channelData, samplesDecoded, sampleRate, bitDepth } =
          await decoder.decodeFile(data);
        decoder.free();
        if (sampleRate >= 8000 && sampleRate <= 96000) {
          originalBuffer = ctx.createBuffer(
            1,
            channelData[0].length,
            sampleRate
          );
          originalBuffer.getChannelData(0).set(channelData[0]);
        } else {
          const newSampleRate = sampleRate < 8000 ? 8000 : 96000;
          const resampler = new Promise((resolve, reject) => {
            create(1, sampleRate, newSampleRate, {
              converterType: ConverterType.SRC_SINC_BEST_QUALITY,
            }).then((src) => {
              const resampled = src.simple(channelData[0]);
              src.destroy();
              resolve(resampled);
            });
          });
          const resampled: any = await resampler;
          originalBuffer = ctx.createBuffer(1, resampled.length, newSampleRate);
          originalBuffer.getChannelData(0).set(resampled);
        }
      } catch (e) {
        originalBuffer = await new Promise<AudioBuffer>((resolve, reject) => {
          ctx.decodeAudioData(
            audioFileResponse.data,
            (buffer) => resolve(buffer),
            (e) => reject(e)
          );
        });
      }
      const origSrHeader = audioFileResponse.headers["x-original-sample-rate"];
      const origSr =
        origSrHeader !== undefined
          ? parseFloat(origSrHeader)
          : originalBuffer.sampleRate;

      // Discard part of the buffer if it starts before the specified start time
      const chunkStartTime = moment(chunk.start_datetime).valueOf();
      const requestedStartTime = start_time_tz.valueOf();
      const chunkEndTime = moment(chunk.end_datetime).valueOf();
      const requestedEndTime = end_time_tz.valueOf() + length;
      if (
        chunkStartTime < requestedStartTime ||
        chunkEndTime > requestedEndTime
      ) {
        const offset = Math.max(
          0,
          (requestedStartTime - chunkStartTime) / 1000
        );
        const offset2 = Math.max(0, (chunkEndTime - requestedEndTime) / 1000);

        const startSample = Math.ceil(offset * origSr);
        const endSample_ = originalBuffer.length - Math.ceil(offset2 * origSr);
        const endSample =
          endSample_ > startSample ? endSample_ : startSample + 1;

        const trimmedBuffer = ctx.createBuffer(
          1,
          endSample - startSample,
          originalBuffer.sampleRate
        );
        trimmedBuffer
          .getChannelData(0)
          .set(
            originalBuffer.getChannelData(0).subarray(startSample, endSample)
          );
        return {
          buffer: trimmedBuffer,
          sr: origSr,
        };
      }

      return {
        buffer: originalBuffer,
        sr: origSr,
      };
    });

    const audioBuffers = await limitConcurrency(tasks, 16);

    // Determine if resampling is necessary
    const allSameSampleRate = audioBuffers.every(
      ({ buffer, sr }: { buffer: any; sr: number }) =>
        buffer.sampleRate === audioBuffers[0].buffer.sampleRate
    );
    let resampledBuffers = audioBuffers;
    if (!allSameSampleRate) {
      const maxSampleRate = Math.max(
        ...audioBuffers.map(
          ({ buffer, sr }: { buffer: any; sr: number }) => buffer.sampleRate
        )
      );
      resampledBuffers = await Promise.all(
        audioBuffers.map(
          async ({ buffer, sr }: { buffer: any; sr: number }) => {
            if (buffer.sampleRate === maxSampleRate) {
              return { buffer, sr };
            }
            const offlineCtx = new OfflineAudioContext(
              1,
              Math.ceil(buffer.duration * maxSampleRate),
              maxSampleRate
            );
            const source = offlineCtx.createBufferSource();
            source.buffer = buffer;
            source.connect(offlineCtx.destination);
            source.start(0);
            return {
              buffer: await offlineCtx.startRendering(),
              sr,
            };
          }
        )
      );
    }

    // Calculate the total duration for the final combined audio, including silent gaps
    let totalDuration = 0;
    const initialSilence =
      (moment(filteredChunks[0].start_datetime).valueOf() -
        start_time_tz.valueOf()) /
      1000;
    if (initialSilence > 0) {
      totalDuration += initialSilence;
    }
    resampledBuffers.forEach(
      ({ buffer, sr }: { buffer: any; sr: number }, index: number) => {
        if (index > 0) {
          const previousEndTime = moment(
            filteredChunks[index - 1].end_datetime
          ).valueOf();
          const currentStartTime = moment(
            filteredChunks[index].start_datetime
          ).valueOf();
          const silentDuration = (currentStartTime - previousEndTime) / 1000;
          if (silentDuration > 0) {
            totalDuration += silentDuration;
          }
        }
        totalDuration += (buffer.duration * buffer.sampleRate) / sr;
      }
    );

    // Add trailing silence if the last chunk ends before the requested end time
    const finalSilence =
      (end_time_tz.valueOf() -
        moment(
          filteredChunks[filteredChunks.length - 1].end_datetime
        ).valueOf()) /
      1000;
    if (finalSilence > 0) {
      totalDuration += finalSilence;
    }

    // Create an empty buffer with enough length to accommodate all chunks and silence
    const maxSampleRate = Math.max(
      ...audioBuffers.map(
        ({ buffer, sr }: { buffer: any; sr: number }) => buffer.sampleRate
      )
    );
    const maxOrigSampleRate = Math.max(
      ...audioBuffers.map(({ buffer, sr }: { buffer: any; sr: number }) => sr)
    );
    const coef =
      maxOrigSampleRate > maxSampleRate
        ? maxOrigSampleRate / maxSampleRate
        : 1.0;
    const newBufferLength = Math.ceil(maxSampleRate * totalDuration * coef);
    const outputBuffer = ctx.createBuffer(1, newBufferLength, maxSampleRate);

    // Copy each buffer into the output buffer, adding silence where needed and trimming overlaps
    let offset = 0;
    if (initialSilence > 0) {
      offset += Math.ceil(initialSilence * maxSampleRate * coef);
    }
    resampledBuffers.forEach(
      ({ buffer, sr }: { buffer: any; sr: number }, index: number) => {
        if (index > 0) {
          const previousEndTime = moment(
            filteredChunks[index - 1].end_datetime
          ).valueOf();
          const currentStartTime = moment(
            filteredChunks[index].start_datetime
          ).valueOf();
          const silentDuration = (currentStartTime - previousEndTime) / 1000;
          if (silentDuration > 0) {
            offset += Math.ceil(silentDuration * maxSampleRate * coef);
          } else if (silentDuration < 0) {
            // Trim the overlapping portion only if there's an overlap
            const overlapDuration = -silentDuration;
            const overlapSamples = Math.ceil(
              overlapDuration * maxSampleRate * coef
            );
            if (overlapSamples < buffer.length) {
              const trimmedBuffer = ctx.createBuffer(
                buffer.numberOfChannels,
                buffer.length - overlapSamples,
                buffer.sampleRate
              );
              for (
                let channel = 0;
                channel < buffer.numberOfChannels;
                channel++
              ) {
                trimmedBuffer
                  .getChannelData(channel)
                  .set(buffer.getChannelData(channel).subarray(overlapSamples));
              }
              buffer = trimmedBuffer;
            }
          }
        }
        outputBuffer
          .getChannelData(0)
          .set(
            buffer
              .getChannelData(0)
              .slice(
                0,
                Math.min(
                  buffer.getChannelData(0).length,
                  newBufferLength - offset
                )
              ),
            offset
          );
        offset += buffer.length;
      }
    );

    return {
      buffer: outputBuffer,
      sr: maxOrigSampleRate,
    };
  } catch (err) {
    console.log(err);
    if (cancel) {
      cancel("Operation canceled");
      return null;
    }
    if (
      data?.message &&
      data?.message.match(
        /System error: index .* is out of bounds for axis .* with size .*/
      )
    ) {
      return null;
    }
    throw err;
  }
}
