import endpoints from "api/endpoints";

import moment from "moment";
import getAudioContext from "components/dataLabeling/audio/getAudioContext";
import axiosNeuron from "api/axios";
import { correctGainInBuffer } from "shared/sensors";
import { FLACDecoder } from "@wasm-audio-decoders/flac";
import { create, ConverterType } from "@alexanderolsen/libsamplerate-js";

const maxChunksAtOnce = 64;
const maxChunkIntervalMs = 3600 * 1000;

export async function getAudioFromChunks(
  placement: number,
  datetimes: number[],
  count: number, // number of samples
  timezone: string
) {
  return new Promise(async (resolve, reject) => {
    try {
      const responses = await Promise.all(
        datetimes.map((datetime: any) => {
          const end_time = moment(datetime).format("YYYY-MM-DD HH:mm:ss.SSS");
          return axiosNeuron.get(
            `${endpoints.audioChunks.default}?placement=${encodeURIComponent(
              placement
            )}&to=${encodeURIComponent(end_time).replace(
              /\./g,
              "%2E"
            )}&ps=${encodeURIComponent(
              count
            )}&order_by=-start_datetime&tz=${encodeURIComponent(
              timezone
            )}&field=id&field=start_datetime&field=sensor_type&field=gain&field=number_of_samples&field=end_datetime&deduplicate=true`
          );
        })
      );

      const sampleRate = responses.reduce(
        (acc: any, cur: any) =>
          Math.max(
            cur.data.results.reduce(
              (acc2: any, cur2: any) =>
                Math.max(
                  acc2,
                  (cur2.number_of_samples /
                    (new Date(cur2.end_datetime).getTime() -
                      new Date(cur2.start_datetime).getTime())) *
                    1000
                ),
              48000
            ),
            acc
          ),
        48000
      );

      const ctx = getAudioContext(sampleRate <= 192000 ? sampleRate : 48000);

      const checkIntervals = (results: any[], reference: Date) => {
        var ref = reference.getTime();
        if (
          results[results.length - 1].startDatetime.getTime() <
          ref - maxChunkIntervalMs
        ) {
          return false;
        }
        var minDiff = maxChunkIntervalMs;
        ref = results[results.length - 1].startDatetime.getTime();
        for (var i = results.length - 2; i >= 0; --i) {
          const t = results[i].startDatetime.getTime();
          const diff = ref - t;
          if (diff < minDiff) minDiff = diff;
          ref = t;
        }
        minDiff *= 2;
        ref = reference.getTime() - minDiff;

        for (var y = results.length - 1; y >= 0; --y) {
          const t = results[y].startDatetime.getTime();
          if (t < ref) return false;
          ref = t - minDiff;
        }
        return true;
      };
      const chunks = [].concat.apply(
        [],
        responses
          .map((response, index) =>
            response.data.results
              .reverse()
              .map(
                (chunk: {
                  id: number;
                  start_datetime: string;
                  gain: number;
                  sensor_type: string | null;
                }) => ({
                  id: chunk.id,
                  startDatetime: new Date(chunk.start_datetime),
                  index,
                  selectionDate: new Date(datetimes[index]),
                  gain: chunk.gain,
                  sensorTypeCodename: chunk.sensor_type,
                })
              )
          )
          .filter(
            (results: any) =>
              results.length &&
              checkIntervals(results, results[0].selectionDate)
          )
      );

      if (!chunks.length) {
        reject("no audio");
        return;
      }

      const getWithRetry = (
        url: string,
        index: number,
        startDatetime: Date,
        selectionDate: Date,
        gain: number,
        sensorTypeCodename: string | null
      ) => {
        for (var i = 5; ; --i) {
          try {
            return axiosNeuron
              .get(url, {
                responseType: "arraybuffer",
              })
              .then(async (response: any) => {
                try {
                  // try to decode with FLACDecoder and resample with libsamplerate-js
                  const decoder = new FLACDecoder();
                  await decoder.ready; // wait for the WASM to be compiled
                  const data = new Uint8Array(response.data);
                  const { channelData, samplesDecoded, sampleRate, bitDepth } =
                    await decoder.decodeFile(data);
                  decoder.free();
                  if (sampleRate >= 8000 && sampleRate <= 96000) {
                    const ret = ctx.createBuffer(
                      1,
                      channelData[0].length,
                      sampleRate
                    );
                    ret.getChannelData(0).set(channelData[0]);
                    return ret;
                  } else {
                    const newSampleRate = sampleRate < 8000 ? 8000 : 96000;
                    const resampler = new Promise((resolve, reject) => {
                      create(1, sampleRate, newSampleRate, {
                        converterType: ConverterType.SRC_SINC_BEST_QUALITY,
                      }).then((src) => {
                        const resampled = src.simple(channelData[0]);
                        src.destroy();
                        resolve(resampled);
                      });
                    });
                    const resampled: any = await resampler;
                    const ret = ctx.createBuffer(
                      1,
                      resampled.length,
                      newSampleRate
                    );
                    ret.getChannelData(0).set(resampled);
                    return ret;
                  }
                } catch (e) {
                  return ctx.decodeAudioData(response.data);
                }
              })
              .then((audio: any) => ({
                data: correctGainInBuffer(sensorTypeCodename, audio, gain),
                index,
                startDatetime,
                selectionDate,
                sensorTypeCodename,
              }));
          } catch (e) {
            if (!i) throw e;
          }
        }
      };

      const audios = chunks.map(
        ({
          id,
          index,
          startDatetime,
          selectionDate,
          gain,
          sensorTypeCodename,
        }: {
          id: number;
          index: number;
          startDatetime: Date;
          selectionDate: Date;
          gain: number;
          sensorTypeCodename: string | null;
        }) =>
          () =>
            getWithRetry(
              `${endpoints.audioChunks.file(id)}`,
              index,
              startDatetime,
              selectionDate,
              gain,
              sensorTypeCodename
            )
      );
      const sensorTypeCodename =
        chunks
          .map((i: any) => i.sensorTypeCodename)
          .filter((i: string | null) => i)[0] || null;
      var sounds: any[] = [];
      while (audios.length) {
        sounds = sounds.concat(
          await Promise.all(
            audios.splice(0, maxChunksAtOnce).map((f: any) => f())
          )
        );
      }
      const totalLength = sounds
        .map((buffer: any) => buffer.data.length)
        .reduce((sum: any, i: any) => sum + i, 0);
      const buffer = ctx.createBuffer(
        1,
        totalLength,
        sounds[0].data.sampleRate
      );
      const offsets: number[] = [0];
      const subOffsets: number[] = [];
      const startDatetimes: Date[] = [];
      const selectionDates: Date[] = [];
      for (let i = 0, offset = 0, index = 0; i < sounds.length; ++i) {
        if (
          !selectionDates.length ||
          selectionDates[selectionDates.length - 1].getTime() !==
            sounds[i].selectionDate.getTime()
        ) {
          selectionDates.push(sounds[i].selectionDate);
        }
        buffer.getChannelData(0).set(sounds[i].data.getChannelData(0), offset);
        if (index !== sounds[i].index) {
          offsets.push(offset);
          index = sounds[i].index;
        }
        subOffsets.push(offset);
        offset += sounds[i].data.length;
        startDatetimes.push(sounds[i].startDatetime);
      }
      offsets.push(totalLength);
      subOffsets.push(totalLength);
      resolve({
        buffer,
        offsets,
        subOffsets,
        startDatetimes,
        selectionDates,
        sensorTypeCodename,
        originalSampleRate: sampleRate,
      });
    } catch (e) {
      reject(e);
    }
  });
}
