import { Box, CircularProgress, useTheme } from "@mui/material";
import { IPlacementList } from "types/placement";
import { IMachineList } from "types/machine";
import { useSelector } from "react-redux";
import { AppState } from "store";
import { CellProps } from "react-table";
import { Label as LabelIcon } from "@mui/icons-material";
import { Event } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";

const Label = <T extends Object>(props: CellProps<T>) => {
  const {
    cell: {
      value: { label, isLabelSound },
    },
  } = props;

  const { machines, placements, labelFetching } = useSelector(
    (state: AppState) => state.eventSounds
  );
  const theme = useTheme();

  const upMd = useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });

  const splitted = label.split("/").map((item: any) => item.trim());
  const machine = splitted[0];
  const placement = splitted[1];

  const placementValue = placements.find(
    (item: IPlacementList) => Number(item.id) === Number(placement)
  );
  const machineValue = machines.find(
    (item: IMachineList) => Number(item.id) === Number(machine)
  );

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      position="relative"
    >
      <Box position={upMd ? "absolute" : "unset"} top={upMd ? "0.5%" : "unset"}>
        {isLabelSound ? <LabelIcon /> : <Event />}
      </Box>
      <div>
        {labelFetching ? (
          <CircularProgress />
        ) : (
          `${machineValue?.name} / ${placementValue?.customName}`
        )}
      </div>
    </Box>
  );
};

export default Label;
