import endpoints from "api/endpoints";
import axiosNeuron from "api/axios";
import { IDataSelection, ILabelSound } from "types/eventSounds";

type IParams = {
  page?: number;
  ps?: number;
  machine?: number[];
  event_type?: string[];
  machine_subcategory?: number[];
  from?: Date | null;
  to?: Date | null;
  ids?: number[];
  placement_type?: number[];
  failure_type?: string[];
  placement?: number[];
  q?: string;
  label_subcategory?: number[];
  value_min?: number;
  value_max?: number;
};

export default async function getLabelSounds(paramsInput: IParams) {
  const url = endpoints.similaritySearchService.labelSounds;
  const params = {
    page: paramsInput.page,
    ps: paramsInput.ps,
    machine: paramsInput.machine ? paramsInput.machine : undefined,
    event_type: paramsInput.event_type ? paramsInput.event_type : undefined,
    machine_subcategory: paramsInput.machine_subcategory
      ? paramsInput.machine_subcategory
      : undefined,
    from: paramsInput.from ? paramsInput.from : undefined,
    to: paramsInput.to ? paramsInput.to : undefined,
    ids: paramsInput.ids ? paramsInput.ids : undefined,
    placement_type: paramsInput.placement_type
      ? paramsInput.placement_type
      : undefined,
    placement: paramsInput.placement ? paramsInput.placement : undefined,
    failure_type: paramsInput.failure_type
      ? paramsInput.failure_type
      : undefined,
    q: paramsInput.q?.length ? paramsInput.q : undefined,
    label_subcategory: paramsInput.label_subcategory
      ? paramsInput.label_subcategory
      : undefined,
    value_min: paramsInput.value_min ? paramsInput.value_min : undefined,
    value_max: paramsInput.value_max ? paramsInput.value_max : undefined,
  };

  try {
    const { data } = await axiosNeuron.get(url, { params });
    data.results = data.results.map((item: ILabelSound, idx: number) => {
      return {
        ...item,
        id: Number(`${item.label.id}${item.placement}${idx}`),
        isPlaying: false,
        isLabelSound: true,
      };
    });
    return data;
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
