import React, { memo, useMemo, useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { KebabMenu } from "components/kebab";
import usePermissions from "shared/hooks/usePermissions";
import EPermissions from "shared/permissions";
import { EditReport } from "../../modal/editReport";
import actions, {
  generateReportAction,
  sendReportAction,
  updateReport,
} from "store/projectDetail/reports/actions";
import moment from "moment";
import { SendReport } from "components/projects/detail/modal/components/reportsList/sendReportModal";
import ConfirmPopup from "components/confirmPopup";

const useStyles = makeStyles((theme: any) => {
  return {
    item: {
      fontSize: theme.custom.typography.fontSize[14],
      color: theme.custom.palette.data,
      fontWeight: theme.custom.typography.fontWeight.normal,
    },
  };
});

type IProps = {
  cell: {
    value: {
      id: number;
      recommendations: string;
      recommendationValue: string;
      inputMaxLength: number;
      companyId: number;
      projectId: number;
      recommendationLengthError: string | null;
      created_for: string;
      link: string;
      userIds: number[];
    };
  };
};

const { setRecommendations, setRecommendationLengthError } = actions;

export const ActionsButton = memo(
  ({
    cell: {
      value: {
        id,
        recommendations,
        recommendationValue,
        inputMaxLength,
        recommendationLengthError,
        created_for,
        link,
        companyId,
        projectId,
        userIds,
      },
    },
  }: IProps) => {
    const dispatch: any = useDispatch();
    const classes = useStyles();
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [confirmRegenerateModal, setConfirmRegenerateModal] = useState(false);

    const disableEdit = usePermissions([
      EPermissions.UPDATE_OWN_MONTHLY_REPORT,
      EPermissions.UPDATE_ANY_MONTHLY_REPORT,
    ]);

    const hideKebabMenu = useCallback(() => {
      const hideKebab = new CustomEvent("hideKebab");
      const kebab = document.querySelector("#kebab-devices-list-" + id);
      kebab?.dispatchEvent(hideKebab);
    }, [id]);

    const onSend = useCallback(() => {
      hideKebabMenu();
      setSendModal(true);
    }, [dispatch, hideKebabMenu, id]);

    const onDownload = useCallback(() => {
      hideKebabMenu();
      if (link) {
        const el = document.createElement("a");
        el.href = link + "&response-content-disposition=attachment";
        el.download = "";
        el.target = "_blank";
        el.click();
      }
    }, [hideKebabMenu, link]);

    const onView = useCallback(() => {
      hideKebabMenu();
      if (link) {
        const el = document.createElement("a");
        el.href = link;
        el.target = "_blank";
        el.click();
      }
    }, [hideKebabMenu, link]);

    const onGenerate = useCallback(async () => {
      hideKebabMenu();
      if (link && !confirmRegenerateModal) {
        setConfirmRegenerateModal(true);
      } else {
        setConfirmRegenerateModal(false);
        dispatch(generateReportAction({ id }));
      }
    }, [dispatch, hideKebabMenu, id, link, confirmRegenerateModal]);

    const onEditing = useCallback(() => {
      hideKebabMenu();
      setIsOpen(true);
    }, [hideKebabMenu, id]);

    const onSubmit = useCallback(() => {
      setIsOpen(false);
      dispatch(
        updateReport(id, {
          recommendations: recommendationValue,
        })
      );
    }, [dispatch, id, recommendationValue, recommendations]);

    const onCancel = useCallback(() => {
      setIsOpen(false);
      dispatch(setRecommendations(recommendations));
      dispatch(setRecommendationLengthError(null));
    }, [dispatch]);

    const inputYearMonth = moment(created_for, "YYYY-MM").format("YYYY-MM");
    const currentYearMonth = moment().format("YYYY-MM");
    const lastMonth = moment().subtract(1, "months").format("YYYY-MM");
    const onSendSubmit = useCallback(() => {
      setSendModal(false);
      dispatch(sendReportAction({ id, users: userIds }));
    }, [dispatch, id, userIds]);

    useEffect(() => {
      dispatch(setRecommendations(recommendations));
    }, [id]);

    const generateItems = useMemo(() => {
      return (
        <>
          <MenuItem
            disabled={!disableEdit}
            className={classes.item}
            onClick={onEditing}
          >
            {t("reports.list.table.edit")}
          </MenuItem>
          {(inputYearMonth === currentYearMonth ||
            inputYearMonth === lastMonth) && (
            <MenuItem
              disabled={!disableEdit}
              className={classes.item}
              onClick={onGenerate}
            >
              {t(
                link
                  ? "reports.list.table.regenerate"
                  : "reports.list.table.generate"
              )}
            </MenuItem>
          )}
          {link ? (
            <>
              <MenuItem className={classes.item} onClick={onDownload}>
                {t("reports.list.table.download")}
              </MenuItem>
              <MenuItem className={classes.item} onClick={onView}>
                {t("reports.list.table.view")}
              </MenuItem>
              <MenuItem
                disabled={!disableEdit}
                className={classes.item}
                onClick={onSend}
              >
                {t("reports.list.table.send")}
              </MenuItem>
            </>
          ) : null}
        </>
      );
    }, [
      disableEdit,
      classes.item,
      onEditing,
      t,
      inputYearMonth,
      currentYearMonth,
      lastMonth,
      onGenerate,
      onDownload,
      link,
      onSend,
    ]);
    return (
      <>
        <KebabMenu id={"kebab-devices-list-" + id} data={generateItems} />

        {isOpen && (
          <EditReport
            recommendations={recommendations}
            id={id}
            onSubmit={onSubmit}
            open={isOpen}
            onCancel={onCancel}
            inputMaxLength={inputMaxLength}
            recommendationLengthError={recommendationLengthError}
            recommendationValue={recommendationValue}
          />
        )}
        {sendModal && (
          <SendReport
            open={sendModal}
            onCancel={() => setSendModal(false)}
            onSubmit={onSendSubmit}
            companyId={companyId}
            projectId={projectId}
            userIds={userIds}
          />
        )}
        {confirmRegenerateModal && (
          <ConfirmPopup
            onConfirm={onGenerate}
            title={t("projects.detail.modal.reports.regenerate.confirm.title")}
            text={t("projects.detail.modal.reports.regenerate.confirm.text")}
            confirmText={t("confirm")}
            noControl={true}
            onCancel={() => setConfirmRegenerateModal(false)}
          />
        )}
      </>
    );
  }
);
