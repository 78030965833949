import { useEffect, useCallback, useRef, useMemo } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { AppState } from "store";

import { createSelector } from "reselect";
import useCompareFetch from "shared/hooks/useCompareFetch";
import actions, {
  fetchEventSounds,
  fetchLabelSounds,
} from "store/eventSounds/actions";

const pageSize = 30;

const selector = createSelector(
  (state: AppState) => state.eventSounds,
  (state: any) => {
    return {
      loading: state.isFetching,
      eventSoundsData: state.eventSoundsData,

      pageIndex: state.pageIndex,
      more: state.more,
      machines: state.machines,
      placements: state.placements,
      isSearchedClicked: state.isSearchedClicked,
      labelSoundsData: state.labelSoundsData,
    };
  }
);

const useData = ({ searchValues }: { searchValues?: any }) => {
  const {
    pageIndex,
    more,
    loading,
    eventSoundsData,
    machines,
    placements,
    isSearchedClicked,
    labelSoundsData,
  } = useSelector(selector, shallowEqual);

  const {
    from,
    to,
    event_type,
    failure_type,
    placement_type,
    machine_subcategory,
    placement,
    machine,
    q,
    label_subcategory,
    valueMin,
    valueMax,
    isEvent,
    isLabel,
  } = searchValues;

  const showMoreClickedTimesRef = useRef(0);
  const sm = useRef(0);

  const dispatch: any = useDispatch();

  const dataSelectionData = isEvent
    ? eventSoundsData
    : isLabel
      ? labelSoundsData
      : [];

  const initialGlobalState = useMemo(() => {
    return {
      pageIndex,
      pageSize,
      pageCount:
        dataSelectionData && dataSelectionData.results
          ? Math.ceil(dataSelectionData.count / pageSize)
          : 0,
    };
  }, [dataSelectionData, pageIndex]);

  const initialEventState = useMemo(() => {
    return {
      pageIndex,
      pageSize,
      pageCount:
        eventSoundsData && eventSoundsData.results
          ? Math.ceil(eventSoundsData.count / pageSize)
          : 0,
    };
  }, [eventSoundsData, pageIndex]);

  const initialLabelState = useMemo(() => {
    return {
      pageIndex,
      pageSize,
      pageCount:
        labelSoundsData && labelSoundsData.results
          ? Math.ceil(labelSoundsData.count / pageSize)
          : 0,
    };
  }, [labelSoundsData, pageIndex]);

  const onPageChange = useCallback(
    (pageIndex: number) => {
      if (sm.current > 0) {
        dispatch(actions.setMore(sm.current));
      } else {
        dispatch(actions.setPage(pageIndex));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (more !== showMoreClickedTimesRef.current) {
      showMoreClickedTimesRef.current = more;
    }
  }, [more]);

  const fetchData = useCallback(
    (params: any) => {
      const commonParams = {
        page: params.page,
        ps: params.pageSize,
        from: params.from ? params.from.toISOString() : undefined,
        to: params.to ? params.to.toISOString() : null,
        machine: params.machine,
        machine_subcategory: params.machine_subcategory,
        placement_type: params.placement_type,
        placement: params.placement,
        q: params.q,
      };
      if (isSearchedClicked) {
        if (params.isEvent && params.isLabel) {
          dispatch(
            fetchEventSounds({
              ...commonParams,
              event_type: params.event_type,
              failure_type: params.failure_type,
            })
          );

          dispatch(
            fetchLabelSounds({
              ...commonParams,
              label_subcategory: params.label_subcategory,
              value_min: params.valueMin,
              value_max: params.valueMax,
            })
          );
        } else if (params.isEvent) {
          dispatch(
            fetchEventSounds({
              ...commonParams,
              event_type: params.event_type,
              failure_type: params.failure_type,
            })
          );
        } else if (params.isLabel) {
          dispatch(
            fetchLabelSounds({
              ...commonParams,
              label_subcategory: params.label_subcategory,
              value_min: params.valueMin,
              value_max: params.valueMax,
            })
          );
        }
      }
    },
    [dispatch, isSearchedClicked]
  );

  useCompareFetch(fetchData, {
    isEvent,
    isLabel,
    page: !sm.current ? pageIndex + 1 : 1,
    pageSize: sm.current ? pageSize * (1 + pageIndex) : pageSize,
    from,
    to,
    event_type,
    failure_type,
    placement_type,
    machine_subcategory,
    placement,
    machine,
    q,
    label_subcategory,
    valueMin,
    valueMax,
  });

  return {
    pageIndex,
    loading,
    eventSoundsData,
    initialGlobalState,
    initialEventState,
    initialLabelState,
    dataSelectionData,
    showMoreClickedTimesRef,
    machines,
    placements,
    isSearchedClicked,
    onPageChange,
    labelSoundsData,
  };
};
export default useData;
