import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import SearchMultiSelect from "components/searchMultiSelect";

interface Props {
  name: string;
  id?: string;
  label: string;
  options: { text: string; value: number | string }[];
  onChangeSubmit: (id: any[]) => void;
  disabled: boolean;
  initialValue?: Array<number | string>;
  searchPlaceholder: string;
  loading: boolean;
  loadMore?: () => void;
  loadingMore?: boolean;
  onSearch?: (val: string) => void;
  highlightedKeysWithIcons?: { key: string; icon: React.ReactElement }[];
}

const SelectField: React.FC<Props> = memo((props: any) => {
  const {
    name,
    label,
    options,
    onChangeSubmit,
    loading,
    disabled,
    initialValue,
    searchPlaceholder,
    id,
    loadMore,
    loadingMore,
    onSearch,
    highlightedKeysWithIcons,
  } = props;
  const { t } = useTranslation();

  return (
    <SearchMultiSelect
      initialValue={initialValue}
      searchPlaceholder={searchPlaceholder}
      id={id || label}
      name={name}
      label={label}
      confirmButtonLabel={t("filter.confirm")}
      deselectButtonLabel={t("filter.deselectAll")}
      options={options}
      loading={loading}
      disabled={disabled}
      onChangeSubmit={onChangeSubmit}
      loadMore={loadMore}
      loadingMore={loadingMore}
      onSearch={onSearch}
      highlightedKeysWithIcons={highlightedKeysWithIcons}
    />
  );
});

export default SelectField;
