import axiosNeuron from "api/axios";

import getAudioContext from "components/dataLabeling/audio/getAudioContext";
import { FLACDecoder } from "@wasm-audio-decoders/flac";
import { create, ConverterType } from "@alexanderolsen/libsamplerate-js";

type IParams = {
  url: string;
};

export default async function getDecodedFile({ url }: IParams) {
  try {
    const ctx = getAudioContext();

    const res = await axiosNeuron.get(url, {
      responseType: "arraybuffer",
    });
    try {
      // try to decode with FLACDecoder and resample with libsamplerate-js
      const decoder = new FLACDecoder();
      await decoder.ready; // wait for the WASM to be compiled
      const byte_data = new Uint8Array(res.data);
      const { channelData, samplesDecoded, sampleRate, bitDepth } =
        await decoder.decodeFile(byte_data);
      decoder.free();
      if (sampleRate >= 8000 && sampleRate <= 96000) {
        const ret = ctx.createBuffer(1, channelData[0].length, sampleRate);
        ret.getChannelData(0).set(channelData[0]);
        return ret;
      } else {
        const newSampleRate = sampleRate < 8000 ? 8000 : 96000;
        const resampler = new Promise((resolve, reject) => {
          create(1, sampleRate, newSampleRate, {
            converterType: ConverterType.SRC_SINC_BEST_QUALITY,
          }).then((src) => {
            const resampled = src.simple(channelData[0]);
            src.destroy();
            resolve(resampled);
          });
        });
        const resampled: any = await resampler;
        const ret = ctx.createBuffer(1, resampled.length, newSampleRate);
        ret.getChannelData(0).set(resampled);
        return ret;
      }
    } catch (e) {
      return await ctx.decodeAudioData(res.data);
    }
  } catch (error) {
    if (error !== undefined) {
      throw error;
    }
  }
}
