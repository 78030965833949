import { memo, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "store";
import { useTranslation } from "react-i18next";
import actions from "store/eventSounds/actions";
import { useAsyncReducer } from "store/selectTemplate";
import { getAvailableLabels } from "api/handlers/dataLabeling/getAvailableLabels";
import SelectField from "components/selectField";
import { createSelector } from "reselect";
import isEqual from "react-fast-compare";

const selector = createSelector(
  (state: AppState) => state.eventSounds.labelSubcategoryValues,
  (filter: any) => {
    return filter;
  }
);

const LabelSubcategory = memo(({ disabled }: { disabled: boolean }) => {
  const dispatch: any = useDispatch();
  const { t } = useTranslation();
  const labelSubcategoryValues: any = useSelector(selector, isEqual);

  const [searchValue, setSearchValue] = useState("");

  const {
    data: labelSubcategoryList,
    loading,
    loadMore,
    loadingMore,
  } = useAsyncReducer(
    labelSubcategoryValues ? "subcategorySelect" : undefined,
    getAvailableLabels,
    {
      ps: 30,
      isRawData: true,
      q: searchValue,
      id: !!labelSubcategoryValues?.length
        ? [...labelSubcategoryValues]
        : undefined,
      not_id: !!labelSubcategoryValues?.length
        ? [...labelSubcategoryValues]
        : undefined,
      isFilter: true,
    }
  );

  const onChange = useCallback(
    (values: string[]) => {
      dispatch(actions.setLabelSubcategoryValues(values));
    },
    [dispatch]
  );

  const handleSearch = useCallback((val: string) => {
    setSearchValue(val);
  }, []);

  const options = labelSubcategoryList
    ? labelSubcategoryList?.results?.map((item: any) => ({
        value: item.id,
        text: item.name,
      }))
    : [];

  return (
    <SelectField
      searchPlaceholder={t(
        "eventSounds.dataSelection.searchPlaceholder.labelSubcategory"
      )}
      initialValue={labelSubcategoryValues || undefined}
      disabled={disabled}
      loading={!labelSubcategoryList && loading}
      name="subcategorySelect"
      label={t("eventSounds.dataSelection.filter.labelSubcategory")}
      id="subcategorySelect"
      options={options}
      onChangeSubmit={onChange}
      loadMore={loadMore}
      loadingMore={loadingMore}
      onSearch={handleSearch}
    />
  );
}, isEqual);

export default LabelSubcategory;
