import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import { dateToDDMMYYYY, dateToHHMM } from "shared/helpers";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import React from "react";
import TextField from "components/textField";
import { maxCharacters } from "shared/form/validators";
import { Field } from "react-final-form";
import Button from "components/button/index";
import { patchComment } from "store/eventModal/commentsActions";
import { useDispatch } from "react-redux";
import usePermissions from "shared/hooks/usePermissions";
import EPermissions from "shared/permissions";
import { useTranslation } from "react-i18next";
import { Theme } from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  input: {
    width: "100%",
  },
  createdBy: {
    fontWeight: "bold",
  },
}));

type TCommentTypeProps = {
  id: number;
  createdBy?: string;
  modifiedBy?: string;
  note: string | null;
  created_at: string;
  loggedUserIsAuthor: boolean;
};
const CommentItem = ({
  id,
  createdBy,
  created_at,
  modifiedBy,
  note,
  loggedUserIsAuthor,
}: TCommentTypeProps) => {
  const dispatch: any = useDispatch();
  const styles = useStyles();
  const { t } = useTranslation();

  const date = new Date(created_at);

  const [editingActive, setEditingActive] = React.useState<boolean>(false);
  const messageRef = React.useRef<any>(null);

  const toggleEditingActive = () => {
    setEditingActive((prev: any) => !prev);
  };

  const [noteValid, setNoteValid] = React.useState<boolean>(false);

  const handleSaveComment = () => {
    const note = messageRef.current.value;
    dispatch(
      patchComment({
        id,
        note,
      })
    );
    toggleEditingActive();
  };

  const canEditComment = usePermissions([
    EPermissions.UPDATE_OWN_EVENT_COMMENT,
    EPermissions.UPDATE_ANY_EVENT_COMMENT,
  ]);

  return (
    <div className={styles.root}>
      <div>
        {createdBy && <span className={styles.createdBy}>{createdBy}</span>}
        &nbsp;
        {created_at && (
          <span>
            {dateToDDMMYYYY(date)} {dateToHHMM(date)}
          </span>
        )}
      </div>
      <div>
        {note && !editingActive && (
          <Box display="flex">
            <p style={{ wordBreak: "break-all" }}>{note}</p>
            {loggedUserIsAuthor && canEditComment && (
              <Box ml={1}>
                <IconButton onClick={toggleEditingActive}>
                  <EditIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        )}
        {note && editingActive && (
          <div>
            <Box mt={2}>
              <Field
                name={`message${id}`}
                inputRef={messageRef}
                className={styles.input}
                component={TextField}
                multiline
                fullWidth
                defaultValue={note}
                validate={(value: any) => {
                  const res = maxCharacters(2000)(value);
                  setNoteValid(!res && !!value);
                  return res;
                }}
              />
            </Box>
            <Box display="flex" my={2}>
              <Button
                color="primary"
                onClick={handleSaveComment}
                disabled={!noteValid}
              >
                {t("eventModal.notes.save")}
              </Button>
              <Box ml={2}>
                <Button color="secondary" onClick={toggleEditingActive}>
                  {t("eventModal.notes.cancel")}
                </Button>
              </Box>
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentItem;
